<template>
  <div v-if="dialogVisible">
    <el-dialog :visible.sync="dialogVisible" custom-class="detail_dialog" :top="'2vh'" :fullscreen="isExpand"
      :show-close="false" :before-close="handleClose" :destroy-on-close="true">
      <template slot="title">
        <div class="title-box">
          <span>报价</span>
          <span class="icon-box">
            <img :src="!isExpand ? expandSvg : collapseSvg" @click="isExpand = !isExpand">
            <i class="el-icon-close" @click="handleClose"></i>
          </span>
        </div>
      </template>
      <el-form ref="form" :model="form" :rules="rules" class="form-box">
        <el-row>
          <el-col :span="8">
            <el-form-item label="供应商名称" label-width="8em">{{ userInfo.tenantName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人" label-width="8em">{{ form.contactName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" label-width="8em">{{ form.contactPhone }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="付款方式" prop="paymentMethod" label-width="8em">
              <el-select v-model="form.paymentMethod" placeholder="请选择付款方式" clearable>
                <el-option v-for="opt in paymentMethodOptions" :key="opt.value" :label="opt.label" :value="opt.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发票类型" prop="invoiceType" label-width="8em">
              <el-select v-model="form.invoiceType" placeholder="请选择发票类型" clearable>
                <el-option v-for="opt in InvoiceTypeEnum" :key="opt.value" :label="opt.label" :value="opt.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="税率" prop="taxRate" label-width="8em">
              <el-select v-model="form.taxRate" placeholder="请选择税率" clearable @change="(v) => { handleTaxRateChange(v) }">
                <el-option v-for="opt in taxRateOptions" :key="opt.optionCode" :label="opt.optionName"
                  :value="opt.optionName">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="交付方式" prop="deliveryMethod" label-width="8em">
              <el-select v-model="form.deliveryMethod" placeholder="请选择交付方式" clearable>
                <el-option v-for="opt in deliveryMethodOptions" :key="opt.value" :label="opt.label" :value="opt.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划交付时间" prop="plannedDeliveryDate" label-width="8em">
              <el-date-picker v-model="form.plannedDeliveryDate" type="datetime" placeholder="请选择计划交付时间"
                value-format="timestamp" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="报价备注" prop="quotePriceRemark" label-width="8em">
              <el-input type="textarea" :rows="3" placeholder="请输入报价备注" :maxlength="200" v-model="form.quotePriceRemark"
                clearable>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="报价附件" prop="inquireQuotePriceAnnexList" label-width="8em">
              <Upload v-model="form.inquireQuotePriceAnnexList" label="回单" type="single"
                accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/png,image/jpeg,image/jpg,application/pdf"
                @remove="handleRemove" :maxSize="10" size="origin" />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="table-wrapper el-form-wrapper">
          <el-row style="margin-bottom: 12px">
            <span style="font-size: 14px; color: #1F2733; font-weight: 600;margin-right: 16px;">报价明细</span>
            <el-button type="primary" @click="handleBatchAdd" :disabled="!selectedKeys.length">批量录入</el-button>
          </el-row>
          <el-table ref="table" border :data="form.itemList" header-row-class-name="table-label"
            @selection-change="handleChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column v-for="e in tableConfig" :key="e.key" :prop="e.key" :width="e.width" :label="e.title"
              :show-overflow-tooltip="e.ellipsis">
              <template v-if="e.rules || e.setRules" #header>
                <span style="color: #F56C6C;margin-right: 4px;font-family: SimSun, sans-serif;">*</span>{{ e.title }}
              </template>
              <template slot-scope="{row, $index: index}">
                <template v-if="e._type === 'text'">{{ row[e.key] || '--' }}</template>
                <el-form-item :prop="'itemList.' + index + '.' + e.key"
                  :rules="e.setRules ? e.setRules(row) : e.rules || []">
                  <div style="display: flex; align-items: center">
                    <el-input v-if="e._type === 'input'" :disabled="e.disabled" v-model="row[e.key]"
                      :placeholder="`请输入${e.title}`" :style="{ width: e.inputWidth }" clearable />
                    <el-input-number v-if="e._type === 'number'" class="number-input" :disabled="e.disabled"
                      v-model="row[e.key]" :min="e.min" :step="e.step" :precision="e.precision"
                      :placeholder="`请输入${e.title}`" clearable @change="() => e.handleChange(row)" />
                    <el-select v-if="e._type === 'select'" :disabled="e.disabled" v-model="row[e.key]"
                      :placeholder="`请选择${e.title}`" @change="v => e.handleChange(v, row)" clearable>
                      <el-option v-for="opt in e._options" :key="opt.value" :label="opt.label" :value="opt.value">
                      </el-option>
                    </el-select>
                    <span v-if="e._type === 'image'">
                      <template v-if="row[e.key] && row[e.key].length > 0">
                      <el-image v-if="row[e.key] && row[e.key].length > 0" style="width: 32px; height: 32px"
                        :src="row[e.key][0].url" :preview-src-list="row[e.key].map(i => i.url)" />
                      <span v-if="row[e.key].length > 1" style="color:#3182bd; margin-left: 2px;">...</span>
                      </template>
                      <template v-else>--</template>
                    </span>
                    <template v-if="e.subs">
                      <div v-for="child in e.subs" :key="child.key" :style="{ width: child.width, marginLeft: '8px' }">
                        <el-select v-if="child._type === 'select'" v-model="row[child.key]"
                          :placeholder="`请选择${child.title}`">
                          <el-option v-for="opt in child._options" :key="opt.value" :value="opt.value">
                            {{ opt.label }}
                          </el-option>
                        </el-select>
                      </div>
                    </template>
                  </div>
                </el-form-item>
              </template>
            </el-table-column>>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="scope">
                <el-popconfirm confirm-button-text="确认" cancel-button-text="取消" title="确认删除？"
                  @confirm="handleDelete(scope.$index)">
                  <a slot="reference" style="color: #f56c6c; cursor: pointer;">删除</a>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" plain @click="handlePreview">预览报价单</el-button>
        <el-button type="info" plain @click="handleClose">取消</el-button>
        <el-button type="info" plain @click="() => handleSave(true)">保存草稿</el-button>
        <el-button type="primary" @click="() => handleSave(false)">提交</el-button>
      </span>
    </el-dialog>
    <el-dialog width="430px" :append-to-body="true" :visible.sync="isBatchImport" title="批量录入"
      :close-on-click-modal="false" :destroy-on-close="true">
      <batch-import :config="editConfig" @cancel="() => (isBatchImport = false)"
        @confirm="handleBatchImportConfirm"></batch-import>
    </el-dialog>
    <el-dialog :visible.sync="printDialogVisible" class="printDialog" top="5vh">
      <div slot="title" style="font-family: PingFangSC, PingFang SC;font-weight: 600;font-size: 14px;color: #1F2733;">
        预览报价单
      </div>
      <PrintTemplate :config="printConfig"></PrintTemplate>
      <template #footer>
        <el-button type="primary" @click="handlePrint">打印</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import InvoiceTypeEnumMap, { InvoiceTypeEnum } from '@/enums/InvoiceTypeEnum';
import { inquireQuotePriceAdd, inquireQuotePriceUpdate, inquireQuotePriceNewestDetail, dictionaryData } from '@/api/inquiryForm.js';
import BatchImport from '@/components/eleBatchImport/index.vue';
import PrintTemplate from '@/components/printTemplate/index.vue';
import * as Moment from 'dayjs';

const compatibilityRequirementMap = {
  1: '原厂全新',
  '原厂全新': 1,
  2: '兼容',
  '兼容': 2,
  3: '原厂兼容',
  '原厂兼容': 3,
};

export default {
  components: { BatchImport, PrintTemplate },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    sparePartList: {
      type: Array,
      default: () => [],
    },
    orderDetail: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    editConfig () {
      return this.tableConfig.filter(i => i._type !== 'text' && i._type !== 'image');
    },
    tableConfig () {
      return [
        {
          title: '备件名称',
          key: 'name',
          width: 160,
          ellipsis: true,
          _type: 'text',
        },
        {
          title: '备件型号',
          key: 'model',
          width: 100,
          ellipsis: true,
          _type: 'text',
        },
        {
          title: '备件规格',
          key: 'spec',
          width: 100,
          ellipsis: true,
          _type: 'text',
        },
        {
          title: '兼容性',
          key: 'compatibilityRequirement',
          width: 130,
          ellipsis: true,
          _type: 'select',
          _options: [
            {
              label: '原厂全新',
              value: '原厂全新',
            },
            {
              label: '兼容',
              value: '兼容',
            },
            {
              label: '原厂兼容',
              value: '原厂兼容',
            },
          ],
          rules: [{ required: true, message: '请选择', trigger: 'change' }],
          handleChange: () => { },
          default: '',
        },
        {
          title: '质保期/保修期',
          key: 'qualityAssPeriod',
          _type: 'number',
          width: 250,
          default: undefined,
          min: 0,
          step: 1,
          precision: 0,
          subs: [
            {
              title: '单位',
              key: 'qualityAssPeriodUnitName',
              _type: 'select',
              width: '100px',
              default: undefined,
              _options: this.warrantyPeriodUnitOptions.map(q => ({
                label: q.optionName,
                value: q.optionName,
              })),
            },
          ],
          rules: [{ required: true, message: '质保期/保修期不能为空', trigger: 'blur' }],
          handleChange: () => { },
        },
        {
          title: '数量（单位）',
          key: 'quantityAndUnit',
          width: 120,
          ellipsis: true,
          _type: 'text',
        },
        {
          title: '单价（元）',
          key: 'price',
          width: 170,
          ellipsis: true,
          _type: 'number',
          min: 0,
          step: 0.01,
          precision: 2,
          rules: [
            {
              validator: (rule, value, callback) => {
                if (!value && value !== 0) {
                  callback(new Error('请输入单价'));
                } else if (value === 0) {
                  callback(new Error('单价不能为0'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
          handleChange: (row) => {
            row.money = (row.quantity && row.price) ? parseInt((row.quantity * 100) * (row.price * 100)) / 10000 : '';
          },
        },
        {
          title: '税率',
          key: 'taxRate',
          width: 100,
          ellipsis: true,
          _type: 'text',
        },
        {
          title: '金额',
          key: 'money',
          width: 100,
          ellipsis: true,
          _type: 'text',
        },
        {
          title: '图片',
          key: 'imageList',
          width: 100,
          ellipsis: true,
          _type: 'image',
        },
        {
          title: '备件编码',
          key: 'code',
          width: 160,
          ellipsis: true,
          _type: 'text',
        },
      ];
    },
  },
  watch: {
    sparePartList: {
      handler (v) {
        if (!this.form.itemList.length) {
          this.form.itemList = v.map(i => ({
            ...i,
            inquirePriceSparePartId: i.inquirePriceSparePartId || i.id,
            quantityAndUnit: `${i.quantity || ''}${i.unitName || ''}` || '--',
            qualityAssPeriodUnitName: '年',
            money: (i.price && i.quantity) ? parseInt((i.price * 100) * (i.quantity * 100)) / 10000 : '',
            compatibilityRequirement: i.compatibilityRequirement ? compatibilityRequirementMap[i.compatibilityRequirement] : '',
          }));
        }
      },
      immediate: true,
    },
    status: {
      handler () {
        this.getDetail();
      },
      immediate: true,
    },
  },
  data () {
    return {
      printConfig: {
        printId: 'queryPrice',
        title: '报价单',
        logo: require('@/assets/logo.svg'),
        contentConfig: [],
      },
      printDialogVisible: false,
      InvoiceTypeEnum,
      isExpand: false,
      expandSvg: require('@/assets/ic_expand.svg'),
      collapseSvg: require('@/assets/ic_collapse.svg'),
      activeName: 'order',
      form: {
        id: undefined,
        contactName: '',
        contactPhone: '',
        paymentMethod: '',
        invoiceType: '',
        taxRate: '',
        deliveryMethod: '',
        plannedDeliveryDate: '',
        quotePriceRemark: '',
        inquireQuotePriceAnnexList: [],
        itemList: [],
      },
      userInfo: {},
      paymentMethodOptions: [
        {
          label: '货到付款',
          value: 1,
        },
        {
          label: '预付款',
          value: 2,
        },
        {
          label: '票到付款',
          value: 3,
        },
        {
          label: '账期付款',
          value: 4,
        },
      ],
      deliveryMethodOptions: [
        {
          label: '上门',
          value: 'HOME',
        },
        {
          label: '快递',
          value: 'EXPRESS',
        },
        {
          label: '自提',
          value: 'SELF_FETCH',
        },
        {
          label: '物流',
          value: 'LOGISTICS',
        },
      ],
      deliveryMethodOptionsMap: {
        HOME: '上门',
        EXPRESS: '快递',
        SELF_FETCH: '自提',
        LOGISTICS: '物流',
      },
      taxRateOptions: [],
      warrantyPeriodUnitOptions: [],
      rules: {
        paymentMethod: [
          { required: true, message: '请选择付款方式', trigger: 'change' },
        ],
        invoiceType: [
          { required: true, message: '请选择发票类型', trigger: 'change' },
        ],
        taxRate: [
          { required: true, message: '请选择税率', trigger: 'change' },
        ],
        deliveryMethod: [
          { required: true, message: '请选择交付方式', trigger: 'change' },
        ],
        plannedDeliveryDate: [
          { required: true, message: '请选择计划交付时间', trigger: 'change' },
        ],
      },
      selectedKeys: [],
      isBatchImport: false,
    };
  },
  methods: {
    formatDate (text) {
      return text ? Moment(text).format('YYYY-MM-DD') : '';
    },
    formatNumberWithCommasAndTwoDecimals (num) {
      if (!num && num !== 0) {
        return '';
      }

      let numStr = num.toFixed(2);

      let [integerPart, decimalPart] = numStr.split('.');

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      return integerPart + (decimalPart ? '.' + decimalPart : '');
    },
    getDesc (key) {
      let ret = '';

      if (key === 'quantity') {
        ret = `${this.orderDetail?.deviceList?.[0].quantity}${this.orderDetail?.deviceList?.[0].unitName}` || '';
      } else {
        ret = this.orderDetail?.deviceList?.[0][key] || '';
      }
      return ret;
    },
    handlePrint () {
      const style
        = '@page { margin: 0; } @media print { * { font-size: 12pt; background-color: red;} body { margin: 1cm; }}';

      this.$print({
        printable: this.printConfig.printId,
        type: 'html',
        targetStyles: ['*'],
        style,
        scanStyles: false,
      });
    },
    handlePrintInfo () {
      const content = {
        type: 'table',
        colCount: 11,
        rows: [],
      };

      content.rows.push(
        {
          cols: [
            {value: '客户名称'},
            {value: this.orderDetail.inquirePriceParty, colSpan: 10},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '地址'},
            {value: this.$route.name === 'RECOMMEND_BUSINESS_OPPORTUNITIES'
              ? `${this.orderDetail.deliveryProvinceName || ''}${this.orderDetail.deliveryCityName
          || ''}` : `${this.orderDetail.deliveryProvinceName || ''}${this.orderDetail.deliveryCityName
            || ''}${this.orderDetail.deliveryAreaName || ''}${this.orderDetail.deliveryDetailAddress || ''}`,
            colSpan: 7},
            {value: '交付方式'},
            {value: this.form.deliveryMethod ? this.deliveryMethodOptionsMap[this.form.deliveryMethod] : '', colSpan: 2},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '联系人'},
            {value: this.form.contactName},
            {value: '手机'},
            {value: this.form.contactPhone, colSpan: 2},
            {value: '电子邮箱'},
            {value: '', colSpan: 2},
            {value: '邮编'},
            {value: '', colSpan: 2 },
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '备件清单', colSpan: 11, style: { fontWeight: 'bold', background: '#f5f5f5' }},
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {value: '序号'},
            {value: '备件名称'},
            {value: '备件型号'},
            {value: '备件规格'},
            {value: '兼容性'},
            {value: '质保期'},
            {value: '数量（单位）'},
            {value: '含税单价（元）'},
            {value: '金额（元）'},
            {value: '交货时间'},
            {value: '商品编码'},
          ],
        },
      );

      this.form.itemList.forEach((i, index) => {
        content.rows.push({
          cols: [
            {value: index + 1},
            {value: i.name},
            {value: i.model},
            {value: i.spec},
            {value: i.compatibilityRequirement},
            {value: `${i.qualityAssPeriod || ''}${i.qualityAssPeriodUnitName || ''}` || ''},
            {value: `${i.quantity || ''}${i.unitName || ''}` || ''},
            {value: i.price},
            {value: i.money},
            {value: this.formatDate(this.form.plannedDeliveryDate)},
            {value: i.code},
          ],
        });
      });

      const footer = {
        name: '合计',
        quantity: 0,
        money: 0,
      };

      (this.form.itemList || []).forEach(i => {
        footer.quantity += (i.quantity || 0) * 1;
        footer.money += (i.money || 0) * 1;
      });

      content.rows.push({
        cols: [
          {value: footer.name},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: ''},
          {value: footer.quantity || ''},
          {value: ''},
          {value: footer.money || ''},
          {value: ''},
          {value: ''},
        ],
      });

      content.rows.push(
        {
          cols: [
            {value: '说明', colSpan: 1, style: { padding: '1em 0', width: '1em', display: 'inline-block' }, tdStyle: { background: '#f5f5f5', textAlign: 'center' }},
            {
              value: [
                `1、开具${this.form.invoiceType ? InvoiceTypeEnumMap[this.form.invoiceType] : '____'}。`,
                '3、本报价单一式贰份，各方个存一份，且同等效力，双方签字盖章生效（传真件有效）。',
              ],
              colSpan: 10,
              tdStyle: {
                padding: '8px 6px',
              },
            },
          ],
        },
      );

      content.rows.push(
        {
          cols: [
            {
              type: 'footer',
              value: [
                {
                  label: '报价单位：',
                  content: this.userInfo.tenantName,
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                  contentStyle: {
                    display: 'inline-block',
                  },
                },
                {
                  label: '授权代表（签字）：',
                  content: '',
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                },
                {
                  label: '日期：',
                  content: '年月日',
                  labelStyle: {
                    display: 'inline-block',
                    width: '9em',
                    textAlign: 'right',
                  },
                  contentStyle: {
                    display: 'inline-block',
                    letterSpacing: '4em',
                    marginLeft: '4em',
                  },
                },
              ],
              colSpan: 11,
              style: {
                width: `${10 + (this.userInfo.tenantName.length < 20 ? 20 : this.userInfo.tenantName.length)}em`,
                display: 'inline-block',
                textAlign: 'left',
                margin: '1em 0',
              },
              tdStyle: {
                textAlign: 'right',
              },
            },
          ],
        },
      );

      this.printConfig.contentConfig = [content];
    },
    handlePreview () {
      this.handlePrintInfo();

      this.printDialogVisible = true;
    },
    handleTaxRateChange (v) {
      this.form.itemList.forEach(i => {
        i.taxRate = v;
      });
    },
    handleBatchImportConfirm (v) {
      const selectedData = this.form.itemList.filter(d => this.selectedKeys.includes(d.id));

      for (const k in v) {
        selectedData.forEach(selected => {
          this.$set(selected, k, v[k]);

          if (k === 'price') {
            const money = (v.price && selected.quantity) ? parseInt((v.price * 100) * (selected.quantity * 100)) / 10000 : 0;
            this.$set(selected, 'money', money);
          }
        });
      }

      this.isBatchImport = false;
    },
    handleChange (selections) {
      this.selectedKeys = selections.map(i => i.id);
    },
    handleClose () {
      this.$emit('update:dialogVisible', false);
    },
    async save (draftFlag) {
      // 新增状态下不存在status字段
      const api = this.form.status ? inquireQuotePriceUpdate : inquireQuotePriceAdd;

      const params = {
        ...this.form,
        inquireQuotePriceAnnexList: (this.form.inquireQuotePriceAnnexList || []).map(i => ({
          ...i,
          annexName: i.annexName || i ? i.annexName : i.name ? i.name.substr(i.name.lastIndexOf('/') + 1) : undefined,
        })),
        taxRate: this.form.taxRate ? this.form.taxRate.replace('%', '') / 100 : '',
        inquirePriceId: this.id,
        draftFlag,
        itemList: this.form.itemList.map(item => ({
          ...item,
          taxRate: item.taxRate ? item.taxRate.replace('%', '') / 100 : '',
          compatibilityRequirement: compatibilityRequirementMap[item.compatibilityRequirement],
        })),
      };

      await api(params);

      this.$message.success('操作成功');

      this.handleClose();
      this.$emit('callback');
    },
    handleSave (draftFlag) {
      if (draftFlag) {
        this.save(draftFlag);
      } else {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.save(draftFlag);
          }
        });
      }
    },
    async getDetail () {
      const { body } = await inquireQuotePriceNewestDetail({
        findDraftFlag: ['WAIT_TASK_ORDER', 'WAIT_QUOTE_PRICE'].includes(this.status),
        inquirePriceId: this.id,
      });

      if (body) {
        body.taxRate = (body.taxRate || body.taxRate === 0) ? parseInt(body.taxRate * 100) + '%' : '';

        const itemList = (body.itemList || []).map(item => ({
          ...item,
          taxRate: body.taxRate,
          qualityAssPeriod: item.qualityAssPeriod ? item.qualityAssPeriod : undefined,
          qualityAssPeriodUnitName: item.qualityAssPeriodUnitName ? item.qualityAssPeriodUnitName : '年',
          money: (item.price && item.quantity) ? parseInt((item.price * 100) * (item.quantity * 100)) / 10000 : '',
          quantityAndUnit: `${item.quantity || ''}${item.unitName || ''}` || '--',
          compatibilityRequirement: compatibilityRequirementMap[item.compatibilityRequirement],
        }));

        this.form = {
          ...body,
          inquireQuotePriceAnnexList: (body.inquireQuotePriceAnnexList || []).map(i => ({
            ...i,
            attachName: i.annexName,
            name: i.annexName,
          })),
          itemList,
        };
      }
    },
    handleRemove (file) {
      const index = this.form.inquireQuotePriceAnnexList.findIndex(v => v.url === file.url);
      this.form.inquireQuotePriceAnnexList.splice(index, 1);
    },
    async getTaxRateDictionaryData () {
      const { body } = await dictionaryData({
        enableFlag: 1,
        typeCode: 'TAX_RATE',
      });

      this.taxRateOptions = body;
    },
    async getUnitDictionaryData () {
      const { body } = await dictionaryData({
        enableFlag: 1,
        typeCode: 'WARRANTY_PERIOD_UNIT',
      });

      this.warrantyPeriodUnitOptions = body;
    },
    handleDelete (index) {
      this.form.itemList.splice(index, 1);
    },
    handleBatchAdd () {
      this.isBatchImport = true;
    },
  },
  mounted () {
    this.userInfo = JSON.parse(this.$local.get('userInfo'));
    this.form.contactName = this.userInfo.name;
    this.form.contactPhone = this.userInfo.phoneNumber;

    this.getTaxRateDictionaryData();
    this.getUnitDictionaryData();
  },
};
</script>

<style scoped lang="scss">
.detail_dialog {
  /deep/ .el-dialog__header {
    padding: 16px 20px;
  }

  .title-box {
    color: #303133;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-box {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;

        &:hover {
          cursor: pointer;
        }
      }

      i {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .el-date-editor.el-input {
    width: 100%;
  }

  .number-input {

    /deep/ .el-input-number__decrease,
    /deep/ .el-input-number__increase {
      display: none;
    }

    /deep/ .el-input__inner {
      padding: 0 15px;
      text-align: left;
    }
  }

  .table-wrapper {
    padding-left: 16px;

    /deep/.el-table__header th {
      background-color: #F2F4F7;
    }

    /deep/ .table-label {
      color: rgba(0, 0, 0, 0.85);
      font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei", 微软雅黑, Arial, sans-serif, PingFangSC-Medium, "PingFang SC";
      font-size: 14px;
      font-weight: bold;
    }
  }

  .form-box {
    .el-form-wrapper {
      flex: 1;
      overflow: auto;

      /deep/ .el-form-item {
        margin-bottom: 0;
      }
    }

    /deep/ .el-form-item.is-error .el-input__inner {
      border-color: #F56C6C !important;
    }
  }
}

.printDialog {
    /deep/ .el-dialog {
      min-width: 250mm;

      .el-dialog__body {
        width: 210mm;
        max-height: 297mm;
        margin: 1cm 2cm;
        overflow: auto;
      }
    }
  }
</style>
