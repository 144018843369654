<template>
  <div class="order_detail">
    <template v-if="id">
      <el-alert title="该医院已过期，请及时付费！" v-if="orderModel.isCustomerExpired" type="warning" effect="dark">
      </el-alert>
      <content-block title="工单进度">
        <div class="order_steps">
          <div v-for="(s, index) in stepList" :key="index" :class="{
            order_step_item: true,
            actived: currentStep === index,
            finished: currentStep > index
          }">
            <div class="order_step_icon">
              <i v-if="(index + 1) < currentStep" class="el-icon-check"></i>
              <span v-else>{{ index + 1 }}</span>
            </div>
            <div class="order_step_title">{{ s.businessStatusStr }}</div>
            <div class="order_step_line"></div>
          </div>
        </div>
        <div v-if="orderModel.dynamicInformList && orderModel.dynamicInformList[0]"
          class="order_progress_sum d-flex flex-row justify-content-between">
          <div class="flex-fill d-flex flex-column justify-content-around ">
            <div class=" d-flex flex-row align-items-center">
              <span class="order_progress_status">{{ orderModel.dynamicInformList[0].actionDesc }}</span>
              {{ orderModel.dynamicInformList[0].operateTime | date-format }}
            </div>
            <span class="order_progress_desc">{{ orderModel.dynamicInformList[0].content }}</span>
          </div>
          <div class=" d-flex flex-row align-items-center cursor-pointer" @click="drawerVisible = true">
            <span>查看动态信息</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </content-block>
    </template>
    <parser ref="newOrderModelRef" class="mt-2" v-if="isLoadedTemplate" :form-conf="schemaDesc" :formModel="orderModel">
    </parser>
    <bottom-panel>
      <el-popover v-if="validateMsgs && validateMsgs[0]" placement="top" width="160" popper-class="valid_msg_list"
        trigger="hover">
        <ul>
          <li v-for="(m, index) in validateMsgs" :key="index" :title="m">{{ m }}</li>
        </ul>
        <el-link slot="reference" type="danger" class="mr-2"><i class="el-icon-warning mr-1"></i>校验不通过</el-link>
      </el-popover>
      <el-button v-if="status === 'COMPLETED' && actionPermissionMap['workOrder:updateCloseTime']" type="primary" class="mr-1" @click="handleClosetime">更新关单时间</el-button>
      <el-button plain class="mr-1" @click="handleClose">关闭</el-button>
      <template v-if="customizeButton.length">
        <el-button type="primary" v-for="btns in customizeButton" :key="btns.id" :icon="btns.icon"
          v-auth="btns.pcAuthCode" @click="customBtsHandle(btns)">{{ btns.name }}</el-button>
      </template>
    </bottom-panel>
    <el-drawer title="动态信息" size="24%" :visible.sync="drawerVisible">
      <el-timeline class="content_block">
        <el-timeline-item v-for="(activity, index) in orderModel.dynamicInformList" :key="index" hide-timestamp
          :type="index === 0 ? 'primary' : ''" placement="top">
          <div class="order_progress_item">
            <div>
              <span>{{ activity.actionDesc }}</span>
              {{ dateFormat(activity.operateTime) }}
            </div>{{ activity.content }}
          </div>
        </el-timeline-item>
      </el-timeline>
    </el-drawer>
    <el-dialog :visible="true" v-if="assignDialogVisible" width="460px" title="指派工单" :close-on-click-modal="false"
      :close-on-press-escape="false" @close="assignDialogVisible = false">
      <el-form label-width="100px" :model="assignForm" ref="assignForm" :rules="assignFormRules">
        <el-form-item label="指派至" prop="assignType">
          <el-radio-group class="assign_type_group" v-model="assignForm.assignType" @change="resetForm">
            <el-radio-button label="1">员工</el-radio-button>
            <el-radio-button v-auth="'workOrder:designateToService'" label="2">服务商</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="assignForm.assignType === '1'" label="选择员工" prop="principalMsg">
          <user-selector :showDepartmt="false" :deviceId="currentInfo.deviceId"
            :workOrderType="currentInfo.customizeBusinessScenarioCode" :value="assignForm.principalMsgId"
            @change="val => assignForm.principalMsg = val"></user-selector>
        </el-form-item>
        <el-form-item v-if="assignForm.assignType === '2'" label="选择服务商" prop="serviceProviderMsg">
          <el-select v-model="assignForm.serviceProviderMsg" value-key="id" clearable>
            <el-option v-for="serviceItem in serviceProviderMsgList" :key="serviceItem.id" :label="serviceItem.name"
              :value="serviceItem"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="assignDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleAssign">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible="true" v-if="timeDialogVisible" width="460px" title="修改关单时间" :close-on-click-modal="false"
      :close-on-press-escape="false" @close="timeDialogVisible = false">
      <el-form label-width="100px" :model="timeForm" ref="timeForm">
        <el-form-item label="关单时间" prop="assignType">
          <el-date-picker v-model="timeForm.closeTime" type="datetime" value-format="timestamp" placeholder="选择关单时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="timeDialogVisible = false">取消</el-button>
        <el-button type="primary" :disabled="!timeForm.closeTime" @click="saveClosetime">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import OrderApi from '@/api/order';
import { queryLayoutByNodeApi } from '@/api/formSetting';
import Parser from '@/components/form-generator/parser/index.js';
import { getDefaultValueByTag } from '@/components/form-generator/utils/component.js';
import { customSettingFormBtnHandle } from '@/api/commonForm';
import UserSelector from '@/components/userSelector';

export default {
  name: 'ORDER_EDIT',
  components: {
    Parser,
    UserSelector,
  },
  props: {
    id: String,
    templateCode: {
      type: String,
    },
    templateId: {
      type: String,
      required: true,
    },
    isSynergy: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      validateMsgs: [],
      // 产品版本
      TRIALPRODUCTCODE: localStorage.userInfo ? JSON.parse(localStorage.userInfo).trialProductCode : '',
      customizeButton: [],
      currentInfo: {
        customizeBusinessScenarioCode: '',
        deviceId: '',
      },
      stepList: [],
      drawerVisible: false,
      assignDialogVisible: false,
      assignForm: {
        principalMsgId: null,
        principalMsg: null,
        assignType: '1',
        serviceProviderMsg: null,
      },
      defaultServiceProviderMsg: {},
      serviceProviderMsgList: [],
      assignFormRules: {
        principalMsg: [
          { required: true, message: '请选择指派人', type: 'object', trigger: ['change', 'blur'] },
        ],
        serviceProviderMsg: [
          { required: true, message: '请选择指派服务商', type: 'object', trigger: ['change', 'blur'] },
        ],
      },
      principalMsg: null,
      dateFormat: this.$options.filters['date-format'],
      isLoadedTemplate: false,
      isAssign: false,
      tenantId: undefined,
      orderModel: {},
      templateType: null,
      dialogVisible: false,
      schemaDesc: {},
      btnsObj: {},
      currentStep: 0,
      timeDialogVisible: false,
      timeForm: {
        closeTime: '',
      },
      status: '',
    };
  },
  methods: {
    async saveClosetime () {
      await OrderApi.updateCloseTime({
        id: this.id,
        ...this.timeForm,
      });

      this.$message.success('修改关单时间成功');

      this.$tabs.refresh();
    },
    handleClosetime () {
      this.timeForm.closeTime = '';
      this.timeDialogVisible = true;
    },
    orderPointHandle (btns) {
      let validateAction = Promise.resolve();
      if (btns.validateRequiredFlag === true) {
        validateAction = this.$refs.newOrderModelRef.validForm();
      }
      this.validateMsgs.splice(0, this.validateMsgs.length);
      validateAction.then(() => {
        const param = {
          ...this.orderModel,
          typeMsg: this.templateType,
          validateRequiredFlag: btns?.validateRequiredFlag,
        };
        customSettingFormBtnHandle({
          url: btns.pcUrl,
          method: btns.httpMethod,
          param,
        }).then(res => {
          if (btns.pcAuthCode !== 'workOrder:assign') {
            this.$message.success(`工单${btns.name}成功`);
            this.handleClose();
          } else {
            if (res.body && res.body[0]) {
              this.$message.error(`${res.body.map(t => `工单编号${t}`).join(',')}指派失败,请核实`);
            } else {
              this.$message.success('指派成功');
              this.assignDialogVisible = false;
              this.getOrderDetail();
            }
          }
        });
      }).catch(error => {
        Object.keys(error).forEach(k => {
          this.validateMsgs = this.validateMsgs.concat(error[k].map(t => t.message));
        });
      });
    },
    customBtsHandle (btns) {
      if (btns.pcAuthCode === 'workOrder:assign') {
        OrderApi.getServiceProvider({
          pageFlag: false,
          isOverdue: false,
          coordinationStatusMsg: {
            value: 'ENABLED',
          },
          orderBy: [{
            orderByType: 'DESC',
            orderByProperty: 'lastUpdateTime',
          }],
        }).then(({ body }) => {
          this.serviceProviderMsgList = body.list || [];
          this.assignDialogVisible = true;
          this.btnsObj = { ...btns };
          if (this.actionPermissionMap['workOrder:designateToService'] && this.defaultServiceProviderMsg?.id) {
            this.assignForm.assignType = '2';
          }
          this.assignForm.serviceProviderMsg = Object.keys(this.defaultServiceProviderMsg).length ? { ...this.defaultServiceProviderMsg } : undefined;
        });
      } else {
        this.orderPointHandle(btns);
      }
    },
    resetForm (val) {
      this.$refs.assignForm && this.$refs.assignForm.clearValidate();
      if (val === '1') {
        this.assignForm.serviceProviderMsg = undefined;
      } else {
        this.assignForm.principalMsg = undefined;
      }
    },
    handleClose () {
      this.$router.go(-1);
    },
    getViewModel (nodeList) {
      nodeList && nodeList.forEach(node => {
        if (node?.__config__?.layout === 'groupRowFormItem') {
          this.getViewModel(node?.__config__?.children);
        } else {
          this.$set(this.orderModel, node.__config__.renderKey, getDefaultValueByTag(node));
        }
      });
    },
    getProgess () {
      return OrderApi.orderProgress({ id: this.templateId }).then(({ body }) => {
        this.stepList = body?.filter(i => !!i.businessStatus);
      });
    },
    getTemplateInfo (param) {
      return queryLayoutByNodeApi({
        ...param,
        customizeBusinessTypeId: this.templateId,
        tenantId: this.tenantId,
        workOrderId: this.id,
      }).then(rs => {
        let isDisabled = !this.actionPermissionMap['workOrder:edit'];
        let formConf = rs.body?.customizeBusinessProcessResDTO?.customizeLayoutResDTO?.schemaDesc || {};
        formConf.disabled = isDisabled;
        this.schemaDesc = formConf;
        this.templateType = rs.body?.customizeBusinessTypeResDTO;
        return rs.body;
      });
    },
    getOrderDetail () {
      OrderApi.detail(this.id).then(res => {
        this.defaultServiceProviderMsg = res.body?.serviceProviderMsg || {};
        this.status = res.body?.status;
        this.currentInfo.deviceId = res.body?.deviceMsg?.id || '';
        this.currentInfo.customizeBusinessScenarioCode = res.body?.typeMsg?.customizeBusinessScenarioCode || '';
        this.currentStep = this.stepList.findIndex(i => i.businessStatus === res.body.status);
        this.tenantId = res.body.tenantId;
        this.getTemplateInfo({
          customizeBusinessTypeId: res.body?.typeMsg?.id,
          status: res.body?.statusMsg.value,
          evalStatus: res.body?.evalStatusMsg.value,
          independentStatus: res.body?.independentStatus,
        }).then(model => {
          this.getViewModel(this.schemaDesc?.fields, res.body);
          this.customizeButton = model.customizeBusinessProcessResDTO?.customizeButtonResDTOList?.filter(i => !!i.pcUrl) || [];

          Object.keys(res.body).forEach(key => {
            if (res.body[key] !== null && res.body[key] !== undefined) {
              this.$set(this.orderModel, key, res.body[key]);
            }
          });
          if (!this.customizeButton[0]) {
            this.schemaDesc.disabled = true;
          }
          this.isLoadedTemplate = true;
        });
      });
    },
    handleAssign () {
      this.$refs.assignForm.validate().then(() => {
        this.orderModel.principalMsg = this.assignForm.principalMsg;
        this.orderModel.serviceProviderMsg = this.assignForm.serviceProviderMsg;
        if (this.assignForm.assignType === '2') {
          this.btnsObj.pcUrl = '/xiaozi-xmb/wireless/saas/workOrder/designateToService';
        }
        this.orderPointHandle(this.btnsObj);
      });
    },

  },
  created () {
    if (this.id) {
      this.getProgess().then(() => {
        this.getOrderDetail();
      });
    } else if (this.templateCode) {
      this.getTemplateInfo({
        code: this.templateCode,
        status: 'NEW',
      }).then(model => {
        this.getViewModel(this.schemaDesc?.fields);
        this.customizeButton = model.customizeBusinessProcessResDTO?.customizeButtonResDTOList || [];
        this.isLoadedTemplate = true;
      });
    }
  },
  mounted () {
    document.title = this.routeTab;
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
    fieldMap () {
      let fieldMap = {};
      let flatterField = (nodeList) => {
        nodeList && nodeList.forEach(node => {
          if (node?.__config__?.layout === 'groupRowFormItem') {
            flatterField(node?.__config__?.children);
          } else {
            fieldMap[node.__config__.renderKey] = node;
          }
        });
      };
      flatterField(this.schemaDesc?.fields);
      return fieldMap;
    },
    routeTab () {
      let text = this.id ? '编辑' : '新增';
      let pageType = this.isSynergy ? '协同工单' : '工单';
      return `${text}${pageType}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.assign_type_group {
  display: flex;
  width: 80%;

  /deep/ .el-radio-button {
    flex: 0 0 49%;

    .el-radio-button__inner {
      display: block;
    }
  }
}
</style>
<style lang="scss">
.order_detail {
  /deep/.content_block {
    margin-bottom: 18px;
  }

  /deep/ .el-row {
    display: flex;
    flex-wrap: wrap;

    .el-col {
      flex-shrink: 0;
    }
  }

  .order_steps {
    display: flex;
    flex-direction: row;

    .order_step_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      color: rgba(36, 40, 50, 0.4);
      font-weight: bold;

      .order_step_icon {
        position: relative;
        z-index: 1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        box-sizing: border-box;
        background: #FFF;
        border: 1px solid rgba(36, 40, 50, 0.4);
        transition: .15s ease-out;
        margin-right: 8px;
      }

      .order_step_line {
        width: 40px;
        height: 1px;
        background: rgba(36, 40, 50, 0.4);
        margin: 0 8px;
      }

      &.finished {
        color: rgba(36, 40, 50, 0.8);

        .order_step_icon {
          border-color: $primary-color;
          color: $primary-color;
        }

        .order_step_line {
          background: $primary-color;
        }
      }

      &.actived {
        .order_step_icon {
          border-color: $primary-color;
          background: $primary-color;
          color: white;
        }
      }

      &:last-child {
        .order_step_line {
          background: transparent;
        }
      }
    }
  }

  .order_progress_sum {
    margin-top: 24px;
    width: 480px;
    height: 64px;
    background: rgba(36, 40, 50, 0.06);
    border-radius: 4px;
    color: rgba(36, 40, 50, 0.6);
    font-size: 12px;
    line-height: 1;
    padding: 12px;
    box-sizing: border-box;

    .order_progress_status {
      font-size: 16px;
      margin-right: 16px;
      color: rgba(36, 40, 50, 0.8);
    }
  }

  .order_progress_item {
    font-size: 12px;
    color: rgba(36, 40, 50, 0.7);
    line-height: 16px;
    vertical-align: middle;

    >div {
      margin-bottom: 8px;

      >span {
        font-size: 14px;
        font-weight: bold;
        margin-right: 12px;
      }
    }
  }
}

.el-popover.valid_msg_list>ul {
  list-style: none;
  color: red;
  max-height: 240px;
  overflow-y: auto;

  &>li {
    line-height: 24px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
