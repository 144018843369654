<template>
  <div class="batch-import-dialog-wrapper">
    <el-form ref="form" :model="form">
      <el-form-item v-for="e in config" :key="e.key" :prop="e.key">
        <div class="assemble-item-wrapper">
          <div>
            <el-checkbox v-model="checkboxForm[e.key]"></el-checkbox>
            {{ e.title }}
          </div>
          <el-input v-if="e._type === 'input'" v-model="form[e.key]" :placeholder="`请输入${e.title}`" />
          <el-date-picker v-if="e._type === 'date'" v-model="form[e.key]" value-format="timestamp" clearable
            :placeholder="`请选择${e.title}`"></el-date-picker>
          <el-input-number v-if="e._type === 'number'" class="number-input" v-model="form[e.key]" :min="e.min"
            :step="e.step" :precision="e.precision" :placeholder="`请输入${e.title}`" />
          <el-select v-if="e._type === 'select'" v-model="form[e.key]" :placeholder="`请选择${e.title}`">
            <el-option v-for="opt in e._options" :key="opt.value" :label="opt.label" :value="opt.value">
            </el-option>
          </el-select>
          <el-date-picker v-if="e._type === 'dateRange'" type="daterange" :placeholder="`请选择${e.title}`"
            v-model="form[e.key]" range-separator="～" value-format="timestamp"></el-date-picker>
          <template v-if="e.subs">
            <div v-for="child in e.subs" :key="child.key" style="width: 110px; margin-left: 8px;">
              <el-select v-if="child._type === 'select'" v-model="form[child.key]" :placeholder="`请选择${child.title}`">
                <el-option v-for="opt in child._options" :key="opt.value" :label="opt.label" :value="opt.value">
                </el-option>
              </el-select>
            </div>
          </template>
        </div>
      </el-form-item>
    </el-form>
    <div class="button-group">
      <el-button @click="handleCancel">取消</el-button>
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BatchImport',
  props: {
    config: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    config: {
      handler (newV) {
        const form = {};
        const checkboxForm = {};

        if (Array.isArray(newV)) {
          newV.forEach(v => {
            form[v.key] = v.default;
            checkboxForm[v.key] = false;

            if (v.subs) {
              v.subs.forEach(child => {
                form[child.key] = child.default;
              });
            }
          });
        }

        this.form = form;
        this.checkboxForm = checkboxForm;
      },
      immediate: true,
    },
  },
  data () {
    return {
      form: {},
      checkboxForm: {},
    };
  },
  methods: {
    handleCancel () {
      this.$emit('cancel');
    },
    handleConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {};

          for (const k in this.checkboxForm) {
            if (this.checkboxForm[k]) {
              data[k] = this.form[k];
            }

            const ret = this.config.find(conf => conf.key === k);

            if (ret?.subs) {
              ret.subs.forEach(child => {
                data[child.key] = this.form[child.key];
              });
            }
          }

          this.$emit('confirm', data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.batch-import-dialog-wrapper {
  .assemble-item-wrapper {
    display: flex;
    align-items: center;

    &>*:first-child {
      width: 120px;
    }

    &>*:nth-child(2) {
      flex: 1;
    }
  }

  .button-group {
    width: 100%;
    padding: 16px;
    text-align: right;
  }

  .number-input {

    /deep/ .el-input-number__decrease,
    /deep/ .el-input-number__increase {
      display: none;
    }

    /deep/ .el-input__inner {
      padding: 0 15px;
      text-align: left;
    }
  }
}
</style>
