<template>
  <div>
    <el-input :size="size" v-if="readonly" ref="input" :value="readonlyText" :placeholder="placeholder" readonly/>
    <el-cascader
      v-else
      :size="size"
      clearable
      :placeholder="placeholder"
      ref="areaCascader"
      v-model.trim="defaultValue"
      :props="cascaderProps"
      @change="handleChange"
    >
    </el-cascader>
  </div>
</template>
<script>
import DistrictApi from '@/api/district';

export default {
  name: 'area_cascader_multiple',
  props: {
    areaLists: {
      type: Array,
      default: () => {
        return [];
      },
    },
    size: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    includeCountry: {
      type: Boolean,
      default: false,
    },
    addresstype: {
      type: Number,
      default: 2,
    },
    placeholder: {
      type: String,
      default () {
        return '请选择地址';
      },
    },
  },
  computed: {
    addresstypeNum: function () {
      return this.addresstype - 1;
    },
  },
  data () {
    return {
      choose: false,
      readonlyText: '',
      defaultValue: [],
      dataCache: new Map(),
      cascaderProps: {
        multiple: true,
        lazy: true,
        value: 'id',
        label: 'fullName',
        lazyLoad: (node, resolve) => {
          const {
            level,
            value = 0,
          } = node;
          let maxLevel = this.addresstypeNum;
          if (level <= maxLevel) {
            DistrictApi.getDistrictListByPid({
              pid: value,
              excludeCountry: level === 0 || false,
            }).then(res => {
              let result = [];
              res.body && res.body.forEach(t => {
                this.dataCache.set(t.id, t);
                result.push(level === maxLevel ? Object.assign({leaf: true}, t) : t);
              });
              resolve(result);
            });
          } else {
            resolve();
          }
        },
      },
    };
  },
  methods: {
    handleChange () {
      this.choose = true;
      this.$refs.areaCascader.presentText = '';
      this.$nextTick(() => {
        const checkedNodes = this.$refs.areaCascader.getCheckedNodes() || [];
        let currentList = [];
        checkedNodes.map(item => {
          if (item.parent) {
            currentList.push({
              provinceId: item.parent.value,
              provinceName: item.parent.label,
              cityId: item.value,
              cityName: item.label,
              type: 'CITY',
            });
          }
        });
        this.$emit('updateBusinessArea', currentList);
      });
    },
    setDefaultValue () {
      if (this.choose) return;
      let temp = this.areaLists.map(item => `${item.provinceName}/${item.cityName}`);
      if (this.readonly) {
        this.readonlyText = temp.toString();
      } else {
        this.$nextTick(() => {
          this.$refs.areaCascader && (this.$refs.areaCascader.presentText = temp.toString());
        });
      }
    },
  },
  watch: {
    areaLists: {
      handler () {
        this.setDefaultValue();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
