<template>
  <div class="login-register">
    <div class="header">
      <img :src="require('@/assets/xiaozi_2x.png')" class="logo">
    </div>
    <div class="content">
      <img :src="require('@/assets/cloud_bg.svg')" class="cloud-bg">
      <div class="main">
        <!-- 手机号登录 -->
        <div v-if="showType === 'phone' && !showCompanyList" class="account-login">
          <div class="tab-panel">
            <div v-for="(tab, index) in loginTypeList"
              :key="'tab-'+index" @click="loginType = tab.type"
              class="tab" :class="{'active': tab.type === loginType}">{{ tab.label }}</div>
          </div>
          <div class="login-panel">
            <div class="login-way">
              <span @click="switchWay('PASSWORD')" :class="{'checked': loginWay === 'PASSWORD'}" style="padding-right: 30px;">账号登录</span>
              <span @click="switchWay('CAPTCHA')" :class="{'checked': loginWay === 'CAPTCHA'}">验证码登录</span>
            </div>
            <template>
              <el-form :model="loginForm" :rules="loginFormRules" ref="loginForm">
                <el-form-item prop="account">
                  <el-input placeholder="请输入手机号" name="account" v-model="loginForm.account" maxlength='11'>
                    <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                  </el-input>
                </el-form-item>
                <el-form-item v-if="loginWay === 'PASSWORD'" prop="password">
                  <el-input placeholder="请输入密码" name="password" v-model="loginForm.password" type="password" show-password >
                    <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                  </el-input>
                </el-form-item>
                <el-form-item v-if="loginWay === 'CAPTCHA'" prop="code">
                  <el-input placeholder="请输入短信验证码" name="code" v-model="loginForm.code" class="code-input">
                    <i slot="prefix" class="el-input__icon el-icon-chat-round"></i>
                    <span slot="append" @click="handleCheckCode('loginForm')" style="cursor: pointer;">{{ loginMsgTip }}</span>
                  </el-input>
                </el-form-item>
              </el-form>
            </template>
            <div class="login_form_msg font-small">{{ loginErrorMsg }}</div>
            <div @click="login" class="login"><i v-if="isLogining" class="el-icon-loading"></i>登录</div>
            <div class="other">其他登录方式</div>
            <div @click="wechatLogin" class="wechat">
              <img :src="require('@/assets/wechat.svg')">
            </div>
          </div>
          <div class="protocol">
            <el-checkbox v-model="checkedPro"></el-checkbox>
            已阅读并同意以下协议<a v-for="(pro,index) in protocolList" :key="'pro-'+index" :href="pro.href" target="_blank">{{ pro.title }}</a>
          </div>
        </div>
        <!-- 选择登录企业列表 -->
        <div v-if="loginType === 'TENANT' && showCompanyList" class="company-list">
          <div class="title">
            <span class="title-tip">请选择企业</span>
            <span @click="backToLogin" class="title-back">返回登录</span>
          </div>
          <company-list :showAdditive="false" :maxHeight="380" :companyLists='companyLists' @refresh="switchCompany" />
        </div>
        <!-- 微信扫码登录 -->
        <div v-if="showType === 'wechat'" class="wechat-login">
          <template v-if="!notBindPhone">
            <p @click="switchPhoneLogin" class="switch-phone">手机号登录</p>
            <div class="wechat-panel">
              <div class="title">微信扫码一键登录</div>
              <div class="tip">未注册的微信号将自动创建小紫平台账号</div>
              <div class="code-box">
                <img v-if="checkedPro" :src="loginQrcode" />
              </div>
              <div class="login_form_msg font-small">{{ loginErrorMsg }}</div>
            </div>
            <div class="protocol">
              <el-checkbox v-model="checkedPro"></el-checkbox>
              已阅读并同意以下协议<a v-for="(pro,index) in protocolList" :key="'pro-'+index" :href="pro.href" target="_blank">{{ pro.title }}</a>
            </div>
          </template>
          <div v-if="notBindPhone" class="bind-phone">
            <div class="bind-title">扫码成功</div>
            <div class="bind-tip">首次扫码登录，请绑定手机号</div>
            <el-form :model="bindForm" :rules="bindFormRules" ref="bindForm">
              <el-form-item prop="account">
                <el-input placeholder="请输入手机号" name="account" v-model="bindForm.account" maxlength='11'>
                  <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input placeholder="请输入短信验证码" name="code" v-model="bindForm.code" class="code-input">
                  <i slot="prefix" class="el-input__icon el-icon-chat-round"></i>
                  <span slot="append" @click="handleCheckCode('bindForm')" style="cursor: pointer;">{{ loginMsgTip }}</span>
                </el-input>
              </el-form-item>
            </el-form>
            <div class="login_form_msg font-small">{{ loginErrorMsg }}</div>
            <div @click="bindPhone" class="login"><i v-if="isLogining" class="el-icon-loading"></i>绑定手机号</div>
            <div @click="backToScan" class="back">暂不绑定，切换其它登录方式</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <span class="filing_desc">Copyright©2021 海南小紫医疗. </span>
      <div class="filing_desc">
        <a class="desc_sty hre" target="_blank" href="http://beian.miit.gov.cn/">琼ICP备2023003828号</a>
        <img class="desc_sty" :src="require('@/assets/imgs/reg.png')" />
        <a target="_blank" class="desc_sty hre" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010502000684">
          琼公网安备 46010502000684号
        </a>
      </div>
    </div>
    <verifition mode="pop"
      captchaType="clickWord"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
      @success="handleCheckCodeSuccess"
    />
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import LoginApi from '@/api/login';
import UserApi from '@/api/systemSetting';
import Verifition from '@/components/verifition';
import CompanyList from '@/components/common/companyList/index.vue';
export default {
  name: 'LOGIN_REGISTER',
  components: {
    Verifition,
    CompanyList,
  },
  data () {
    return {
      checkedPro: false,
      loginType: 'PERSON', // PERSON
      loginTypeList: [
        { type: 'PERSON', label: '个人登录' },
        { type: 'TENANT', label: '企业登录' },
      ],
      protocolList: [
        {
          title: '《服务条款》、',
          href: `${process.env.VUE_APP_HOST}xzfwtk.html`,
        },
        {
          title: '《隐私协议》',
          href: `${process.env.VUE_APP_HOST}xzysxy.html`,
        },
      ],
      isLogining: false,
      loginWay: 'PASSWORD',
      loginForm: {
        account: '',
        password: '',
        code: '',
      },
      showType: 'phone', // wechat
      loginMsgTip: '获取验证码',
      loginErrorMsg: '',
      loginFormRules: {
        account: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号码格式有误，请输入正确的手机号码', trigger: 'blur'},
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur'},
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur'},
        ],
      },
      companyLists: [],
      showCompanyList: false, // false
      loginQrcode: '', // 微信登录二维码url
      sceneStr: '', // 微信登录场景值
      loginTimer: null,
      notBindPhone: false,
      bindForm: {
        account: '',
        code: '',
      },
      bindFormRules: {
        account: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号码格式有误，请输入正确的手机号码', trigger: 'blur'},
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur'},
        ],
      },
    };
  },
  beforeDestroy () {
    clearInterval(this.loginTimer);
  },
  methods: {
    ...mapActions({
      getMenuInfo: 'getMenuInfo',
    }),
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),
    switchWay (way) {
      this.$refs['loginForm'].clearValidate();
      this.loginWay = way;
    },
    switchPhoneLogin () {
      this.showType = 'phone';
      clearInterval(this.loginTimer);
    },
    backToLogin () {
      this.isLogining = false;
      this.loginErrorMsg = '';
      this.showCompanyList = false;
    },
    backToScan () {
      this.isLogining = false;
      this.showType = 'phone';
      clearInterval(this.loginTimer);
      // this.wechatLogin();
    },
    async wechatLogin () {
      if (!this.checkedPro) {
        this.$message({
          message: '请先阅读并同意协议',
          type: 'warning',
          center: true,
        });
        return;
      }
      this.notBindPhone = false;
      this.loginErrorMsg = '';
      const { body } = await LoginApi.getWechatQrcode();
      this.loginQrcode = body.loginQrcode;
      this.sceneStr = body.sceneStr;
      this.showType = 'wechat';

      this.loginTimer = setInterval(() => {
        LoginApi.checkWechatLogin({sceneStr: this.sceneStr}).then(res => {
          // code：校验编码：SUCCESS 登录成功，返回登录的token信息 ；WAIT_SCAN 正在等待用户扫码，FAILURE 登录失败，返回失败原因 message ；NEED_PHONE：需要再次关联手机号,可用值:SUCCESS,WAIT_SCAN,FAILURE,NEED_PHONE
          if (['SUCCESS', 'FAILURE', 'NEED_PHONE'].includes(res.body.code)) {
            clearInterval(this.loginTimer);
          }
          if (res.body.code === 'SUCCESS') {
            this.$local.set('token', res.body.loginInfo.token);
            this.initOwnerList();
          }
          if (res.body.code === 'FAILURE') {
            this.loginErrorMsg = res.body.message;
          }
          if (res.body.code === 'NEED_PHONE') {
            this.notBindPhone = true;
          }
        });
      }, 5000);
    },
    bindPhone () {
      if (this.isLogining) return;
      this.loginErrorMsg = '';
      this.$refs.bindForm.validate().then(valid =>{
        if (valid) {
          this.isLogining = true;
          const param = {
            entryType: '', // 登录入口方式（个人版-PERSON，企业版-TENANT）
            type: 'CAPTCHA', // 登录方式:PASSWORD,CAPTCHA
            loginSource: '2', // 登录入口（1-小程序，2-PC端）
            account: this.bindForm.account, // 手机号
            code: this.bindForm.code, // 密码、验证码
          };
          // 校验验证码
          LoginApi.login(param).then(()=>{
            const param2 = {
              phoneNumber: this.bindForm.account,
              sceneStr: this.sceneStr,
            };
            // 绑定手机号并登录
            LoginApi.bindPhone(param2).then((res)=>{
              this.$local.set('token', res.body.token);
              this.initOwnerList();
            }, ({heads})=>{
              this.loginErrorMsg = heads?.message;
              this.isLogining = false;
            }).finally(() => {
              this.isLogining = false;
            });
          }, ({heads})=>{
            this.loginErrorMsg = heads?.message;
            this.isLogining = false;
          });
        }
      });
    },
    login () {
      if (!this.checkedPro) {
        this.$message({
          message: '请先阅读并同意协议',
          type: 'warning',
          center: true,
        });
        return;
      }
      if (this.isLogining) return;
      this.loginErrorMsg = '';
      this.$refs.loginForm.validate().then(valid =>{
        if (valid) {
          this.isLogining = true;
          const param = {
            entryType: this.loginType, // 登录入口方式（个人版-PERSON，企业版-TENANT）
            type: this.loginWay, // 登录方式:PASSWORD,CAPTCHA
            loginSource: '2', // 登录入口（1-小程序，2-PC端）
            account: this.loginForm.account, // 手机号
            code: this.loginWay === 'PASSWORD' ? this.loginForm.password : this.loginForm.code, // 密码、验证码
          };
          LoginApi.login(param).then((res)=>{
            this.$local.set('token', res.body.token);
            this.initOwnerList();
          }, ({heads})=>{
            this.loginErrorMsg = heads?.message;
            this.isLogining = false;
          }).finally(() => {
            this.isLogining = false;
          });
        }
      });
    },
    initOwnerList () {
      LoginApi.ownerList().then(resp => {
        let cloneList = JSON.parse(JSON.stringify(resp.body));
        let personalCompany = cloneList.find(item => item.isPersonal === true);
        // resp.body = resp.body.filter(item => item.isPersonal === false);
        resp.body = resp.body.splice(resp.body.findIndex(item => item.isPersonal), 1).concat(resp.body);
        this.$local.set('ownerList', resp.body);
        this.isLogining = false;

        // 个人登录、微信扫码登录
        if (this.loginType === 'PERSON' || this.showType === 'wechat') {
          if (resp.body.length === 1) {
            this.$local.set('haveCompany', false);
          } else {
            this.$local.set('haveCompany', true);
          }
          this.switchCompany(personalCompany);
        }
        // 企业登录
        if (this.loginType === 'TENANT') {
          if (resp.body.length === 1) {
            this.$local.set('haveCompany', false);
            this.switchCompany(personalCompany, 'tenant');
          } else {
            this.companyLists = resp.body;
            this.showCompanyList = true;
            this.$local.set('haveCompany', true);
          }
        }
      });
    },
    async handleCheckCode (refname) {
      if (this.loginMsgTimerTick > 0) {
        return;
      }
      this.$refs[refname].validateField('account', valid=>{
        !valid && this.$refs.verify.show();
      });
    },
    handleCheckCodeSuccess (captchaVO) {
      let phone = this.loginForm.account;
      if (this.notBindPhone) {
        phone = this.bindForm.account;
      }
      LoginApi.sendValidCode({captchaVO, phone, captchaType: 'LOGIN' }).then(() =>{
        if (!this.loginMsgTimerId) {
          this.loginMsgTimerTick = 120;
          this.loginMsgTimerId = setInterval(() => {
            this.loginMsgTimerTick--;
            this.loginMsgTip = `重新获取(${this.loginMsgTimerTick})`;
            if (this.loginMsgTimerTick === 0) {
              clearInterval(this.loginMsgTimerId);
              this.loginMsgTimerId = '';
              this.loginMsgTip = '获取验证码';
            }
          }, 1000);
        }
      });
    },
    switchCompany (tenant, type) {
      LoginApi.switchCompany(tenant.tenantId).then(() =>{
        Promise.all([UserApi.getDetailEmploy({id: tenant.employeeId, findDepartmentFlag: true, findRoleFlag: true}),
          this.getMenuInfo(),
        ]).then(results=>{
          this.$local.set('userInfo', results[0].body);
          this.updateUserInfo(results[0].body);
          this.$router.replace({
            path: this.$store.getters.firstUrl,
            query: {
              loginType: type,
            },
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-register {
  width: 100%;
  min-height: 100vh;
  background-image: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  .login_form_msg {
    line-height: 20px;
    // height: 40px;
    color: rgba(252, 52, 52, 0.6);
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .login {
    cursor: pointer;
    text-align: center;
    width: 100%;
    height: 42px;
    line-height: 42px;
    background: #004190;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
  }
  .header {
    padding: 10px 280px;
    background-color: #FFFFFF;
    .logo {
      width: 183px;
      height: 48px;
    }
  }
  .content {
    margin: 0 auto;
    width: 840px;
    height: 500px;
    background: #FFFFFF;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.08);
    margin-top: 15vh;
    position: relative;
    .cloud-bg {
      position: absolute;
      top: 0;
      left: -140px;
    }
    .main {
      position: absolute;
      top: 0;
      right: 0;
      width: 440px;
      height: 500px;
      background-color: #FFFFFF;
      .protocol {
        text-align: center;
        font-size: 14px;
        color: #929AA6;
        > a {
          color: #004190;
          cursor: pointer;
        }
      }
      .account-login {
        .tab-panel {
          display: flex;
          justify-content: space-between;
          padding: 0 60px;
          border-bottom: 1px solid #EBEDF0;
          .tab {
            font-size: 16px;
            font-weight: 600;
            color: #1F2733;
            letter-spacing: 1px;
            padding: 15px;
            border-bottom: 2px solid transparent;
            &:hover {
              color: #004190;
              cursor: pointer;
            }
          }
          .active {
            color: #004190;
            border-bottom: 2px solid #004190;
          }
        }
        .login-panel {
          padding: 25px 40px;
          .login-way {
            cursor: pointer;
            padding-bottom: 18px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
          }
          .checked {
            font-size: 18px;
            font-weight: 500;
            color: #004190;
          }
          .other {
            font-size: 14px;
            font-weight: 400;
            color: #929AA6;
            margin-top: 40px;
            width: 100%;
            height: 20px;
            line-height: 20px;
            text-align: center;
            position: relative;
            &:before, &:after {
              position: absolute;
              background: #EBEDF0;
              content: "";
              height: 1px;
              top: 50%;
              width: 35%;
            }
            &:before {
              left: 0;
            }
            &:after {
              right: 0;
            }
          }
          .wechat {
            cursor: pointer;
            text-align: center;
            padding: 20px 0;
            img {
              width: 26px;
              height: 20px;
            }
          }
        }
      }
      .company-list {
        padding: 20px;
        width: 440px;
        height: 500px;
        overflow-y: hidden;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title-tip {
            font-size: 16px;
            font-weight: 400;
            color: #1F2733;
          }
          .title-back {
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            color: #004190;
          }
        }
      }
      .wechat-login {
        padding: 25px 20px;
        .switch-phone {
          cursor: pointer;
          text-align: right;
          font-size: 14px;
          font-weight: 400;
          color: #004190;
        }
        .wechat-panel {
          height: 400px;
          padding-top: 45px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            font-size: 18px;
            font-weight: 500;
            color: #1F2733;
          }
          .tip {
            padding-top: 10px;
            padding-bottom: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #929AA6;
          }
          .code-box {
            background: #FFFFFF;
            border-radius: 6px;
            border: 1px solid #DFE2E8;
            font-size: 14px;
            font-weight: 400;
            color: #929AA6;
            width: 180px;
            height: 180px;
            padding: 15px;
            box-sizing: border-box;
            > img {
              width: 150px;
              height: 150px;
            }
          }
        }
        .bind-phone {
          padding: 55px 30px 0;
          .bind-title {
            font-size: 18px;
            font-weight: 500;
            color: #1F2733;
          }
          .bind-tip {
            font-size: 14px;
            font-weight: 400;
            color: #929AA6;
            padding-top: 7px;
            padding-bottom: 20px;
          }
          .back {
            cursor: pointer;
            padding-top: 15px;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #237FFA;
          }
        }
      }
    }
  }
  .footer {
    text-align: center;
    padding-top: 10vh;
    padding-bottom: 5vh;
    font-size: 14px;

    * {
      color: #1F2733;
    }
    .filing_desc {
      display: block;
      .desc_sty {
        display: inline-block;
        vertical-align: middle;
        margin-right: 3px;
      }
    }
  }
  /deep/ .el-input--small .el-input__inner {
    height: 42px !important;
  }
  /deep/ .el-input__inner:focus {
    border-color: #004190 !important;
  }
  .code-input {
    /deep/ .el-input-group__append{
      background: #FFFFFF;
      color: #237FFA;
    }
  }
}
</style>
