<!-- 企业管理 -->
<template>
  <div>
    <ContentBlock style="min-height:calc(100vh - 150px)">
      <div class="top"><b>企业资料</b> <div style="flex:1;text-align:right;"><el-tag style="cursor: pointer;" @click="handleSetting"><i class="el-icon-s-tools" style="margin-right: 5px;"></i>设置</el-tag></div></div>
      <div class="companyContainer">
        <div class="company_top">
          <div class="img" @click="handleUpload">
            <el-avatar shape="square" :size="60" v-if="$store.state.company.currentCompanyInfo.logo" :src="$store.state.company.currentCompanyInfo.logo"></el-avatar>
            <template v-else>{{ $store.state.company.currentCompanyInfo.name?$store.state.company.currentCompanyInfo.name.substring(0,2):'' }}</template>
          </div>
          <div class="right">
            <div class="topTitle">
              <b>{{ $store.state.company.currentCompanyInfo.name }}</b>
              <template v-if="['1'].includes($store.state.company.currentCompanyInfo.authenticationStatus)">
                <time class="grey"><img :src="require('@/assets/ic_weirenzheng.svg')" />未认证</time>
                <span class="approveBtn" @click="goToCertification">前往认证&nbsp;&gt;</span>
              </template>
              <template v-else-if="['3'].includes($store.state.company.currentCompanyInfo.authenticationStatus)">
               <time><img :src="require('@/assets/ic_yirenzheng.svg')" />已认证</time>
               <span class="approveBtn" @click="goToCertification">查看详情&nbsp;&gt;</span>
              </template>
              <template v-else-if="['2'].includes($store.state.company.currentCompanyInfo.authenticationStatus)">
                <time class="yellow"><img :src="require('@/assets/ic_renzhengzhong.svg')" />认证中</time>
                <span class="approveBtn" @click="goToCertification">查看详情&nbsp;&gt;</span>
              </template>
            </div>
            <div class="content"><span>组织编号：{{ companyInfo.code }}</span><span>经营类型：{{ ['', '厂商', '服务商', '经销商', '其它'][companyInfo.businessType] }}</span><span>公司规模：{{ companyInfo.scale }}</span></div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="company_bottom">
          <div class="companyContent">企业介绍：<span>{{ companyInfo.remark }}</span></div>
        </div>
      </div>
    </ContentBlock>
    <CompanyInformation ref="JZCompanyInformation" @updateCompanyInfo="getTenantBaseInfo"></CompanyInformation>
    <div style="visibility: hidden;">
      <Upload
        v-model="imageLists"
        label="附件信息"
        buttonText="上传"
        type="single"
        ref="JZUpload"
        :show-file-list="false"
        @success="handleFileLists"
        @remove="handleRemove"
        accept="image/png,image/jpeg,image/jpg,application/pdf"
        :tip="false"
        :maxSize="10"
        size="origin"
        :limit="2"
      >
      </Upload>
    </div>
    <AccreditationDialog ref="JZAccreditationDialog"></AccreditationDialog>
  </div>
</template>

<script>
import CompanyInformation from './component/companyInformation';
import { getDetailTenantInfo } from '@/api/systemSetting';
import AvaterMixin from './mixins/avater';
export default {
  name: '',
  components: {
    CompanyInformation,
  },
  mixins: [AvaterMixin],
  created () {
    this.initPage();
  },
  methods: {
    initPage () {
      this.entryStatus = JSON.parse(localStorage.getItem('entryStatus'));
      this.getTenantBaseInfo();
    },
    async getTenantBaseInfo () {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const { body } = await getDetailTenantInfo({id: userInfo.tenantId});
      this.companyInfo = body;
      this.$store.dispatch('UPDATE_COMPANYINFO', body);
    },
    handleSetting () {
      let companyInfo = this.companyInfo;
      this.$refs.JZCompanyInformation.form = {
        name: companyInfo.name,
        code: companyInfo.code,
        businessType: companyInfo.businessType,
        scale: companyInfo.scale,
        remark: companyInfo.remark,
        tenantId: companyInfo.id,
      };
      this.$refs.JZCompanyInformation.authenticationStatus = companyInfo.authenticationStatus;
      this.$refs.JZCompanyInformation.initPage();
    },
  },
  data () {
    return {
      companyInfo: {},
      entryStatus: {},
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .content_block{
  padding:0 20px 20px 20px;
}
/deep/ .top{
  display:flex;
  height:48px;
  align-items: center;
  line-height: 48px;
}
/deep/ .companyContainer{
  display: flex;
  flex-direction: column;
  .company_top{
    display: flex;
    .img{
      width: 60px;
      height: 60px;
      background: #E3ECFF;
      border-radius: 4px;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #1A66FF;
      line-height: 60px;
      text-align: center;
      margin-right: 10px;
      cursor: pointer;
      border-radius: 1px solid #dddddd;
    }
    .right {
      flex: 1;
      padding:4px;
      .topTitle{
        height:32px;
        line-height: 32px;
        b{
          font-weight: 600;
          font-size: 18px;
          color: #1F2733;
          letter-spacing: 0;
          text-align: left;
          margin-bottom: 0;
        }
        time{
          border-radius: 4px;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          background: #E5FEEB;
          color: #38D677;
          letter-spacing: 0;
          height: 24px;
          padding: 2px 6px;
          line-height: 20px;
          margin-right: 8px;
          margin-left: 8px;
          img{
            display: inline-block;
            vertical-align: middle;
            margin-top: -2px;
            margin-right:4px
          }
          &.grey{
            background: #F2F4F7;
            color: #1F2733;
          }
          &.yellow{
            background: #FEFAE0;
            color: #FAB007;
          }
        }
        .approveBtn {
          color:#237FFA;
          cursor: pointer;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          letter-spacing: 0;
          text-align: left;
        }
      }

      .content{
        margin-bottom: 2px;
        span{
          padding-right: 32px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #5F6A7A;
          letter-spacing: 0;
        }
      }
    }
  }
  .company_bottom{
    .companyContent{
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #5F6A7A;
      letter-spacing: 0;
      text-align: left;
      line-height: 20px;
      display: flex;
      span{
        flex: 1;
        line-height: 20px;
      }
    }
  }
}
/deep/ .serviceContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &>div{
    width: 272px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 16px;
    border: 1px solid #EBEDF0;
    min-height: 80px;
    margin-right: 32px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:hover{
      box-shadow: 2px 2px 4px 0px rgba(215,219,224,0.7);
    }
    .ser_top{
      display: flex;
      div.service_container_content{
        flex: 1;
        .service_top{
          letter-spacing: 0;
          text-align: left;
          line-height: 24px;
          b{
            font-size: 14px;
            color: #1F2733;
            margin-bottom: 0;
            font-weight: 600;
            margin-right: 6px;;
          }
          time{
            font-size: 12px;
            color: #237FFA;
            letter-spacing: 0;
            text-align: center;
            background: #E2F3FE;
            border-radius: 4px;
            line-height: 20px;
            padding: 2px 8px;
            height: 24px;
            font-weight: 400;
            &.grey{
              background: #F2F4F7;
              color: #1F2733;
            }
            &.blue{
              color: #237FFA;
              background: #E2F3FE;
            }
            &.green{
              color: #E5FEEB;
              background: #237FFA;
            }
            &.yellow{
              color: #FEFAE0;
              background: #FAB007;
            }
            &.red{
              color: #FF5959;
              background: #FEE9E5;
            }
          }

        }
        .service_bottom{
          font-size: 12px;
          color: #5F6A7A;
          letter-spacing: 0;
          text-align: left;
        }
      }
    }
    .ser_bottom{
      margin-top: 5px;
      span{
        padding:4px 5px;
        background-image: linear-gradient(90deg, #F2F4F7 0%, #FFFFFF 100%);
      }
    }
  }
}
/deep/.el-tabs__header{
  margin-bottom: 0;
  .el-tabs__item{
    height: auto;
    padding-bottom: 6px;
  }
}
</style>
