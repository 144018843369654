<template>
  <div class="header">
    <div class="menu">
      <div class="menu_action">
        <i :class="`${isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'} cursor-pointer`" @click="handleCollapse"></i>
      </div>
      <!-- 头部导航 方法一 -->
      <!-- <el-menu v-show="headerList && headerList[0]" :default-active="currentModule"  text-color="#242832" active-text-color="#237FFA" mode="horizontal" @select="handleSelectMenu">
        <el-menu-item v-for="item in headerList" :key="item.id" :index="item.url" >{{item.name}}</el-menu-item>
      </el-menu> -->

      <!-- 头部导航 方法二 -->

      <ul class="el-menu--horizontal el-menu" v-show="headerList && headerList[0]">
        <li class="el-menu-item" :class="{ 'is-active': active === item.url }" v-for="item in headerList" :key="item.id"
          @click="handleSelectMenu(item.url)">
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <el-button v-auth="'workOrder:hospital:board'" type='primary' round size="small" icon='el-icon-s-platform'
      style="margin-right:10px;" @click="handleFullscreen(2)" v-if="sysFlag === 'yxb'">
      医院数据看板
    </el-button>
    <el-button v-auth="'board'" type='primary' round size="small" icon='el-icon-s-platform' style="margin-right:10px;"
      @click="handleFullscreen(1)" v-if="sysFlag === 'yxb'">
      数据看板
    </el-button>
    <div class="d-flex align-items-center">
      <div v-if="TRIALPRODUCTCODE === '2' && msgShowType !== null" class="payment_notice mr-3"
        @click="handleOpenedManually">
        <template v-if="msgShowType">
          您有
          <span>{{ expriryList.length }}</span>
          家医院待付费，
        </template>
        <template v-else>
          <template v-if="currentUserInfo.expiryDays < 0">账号已过期，</template>
          <template v-else>
            剩余
            <span>{{ currentUserInfo.expiryDays }}</span>
            天到期，
          </template>
        </template>
        <span>请点击支付</span>
      </div>
      <el-dropdown v-if="active === '\/orderWall'" :hide-on-click="false" id="guide_help_btn" ref="guide_help_btn"
        class="d-flex align-items-center mr-3" placement="bottom" trigger="click">
        <div class="help_center">
          <i class="iconfont icon-wenhao help_center_icon icon-ic_bangzhuzhongxin"></i>
          <span class="help_center_text">帮助</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item id="helpcenter" @click.native="openHelpDoc">帮助手册</el-dropdown-item>
          <el-dropdown-item @click.native="goToFeedback">意见反馈</el-dropdown-item>
          <!-- <el-dropdown-item @click.native="openGuideModelHandle">新手引导</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click" id="personl_help_btn" ref="personl_help_btn" class="user_Info"
        @visible-change="visibleChange" :hide-on-click="false" size="normal">
        <div class="cursor-pointer">
          <div class="userInfo_name">
            <div class="userInfo_name-left">
              <img v-if="this.$store.getters.currentUserInfo && this.$store.getters.currentUserInfo.headUrl"
                :src="this.$store.getters.currentUserInfo.headUrl" class="head" />
              <el-avatar v-else icon="el-icon-user-solid" :size="40" class="font-large"></el-avatar>
            </div>
            <!-- 个人用户或者当前企业 -->
            <div class="userInfo_name-right">
              <span class="name">{{ this.$store.getters.currentUserInfo && (this.$store.getters.currentUserInfo.nickName
                ||
                this.$store.getters.currentUserInfo.name) }}</span>
              <div class="name-ellipsis">{{ this.$store.getters.currentUserInfo.isPersonal ? '小紫平台个人用户' : tenantName }}</div>
            </div>
          </div>
          <i :class="[userMenuVisible ? 'el-icon-caret-top' : 'el-icon-caret-bottom', 'el-icon--right']"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <div class="user-switch-quit-box">
            <img v-if="this.$store.getters.currentUserInfo && this.$store.getters.currentUserInfo.headUrl"
              :src="this.$store.getters.currentUserInfo.headUrl" @click="handleRedirectToUserInfo" class="head" />
            <el-avatar v-else @click.native="handleRedirectToUserInfo" icon="el-icon-user-solid" :size="50"></el-avatar>
            <div @click="handleRedirectToUserInfo" class="name">{{ this.$store.getters.currentUserInfo &&
              (this.$store.getters.currentUserInfo.nickName || this.$store.getters.currentUserInfo.name) }}</div>
            <el-popover v-model="visible" placement="left-start" width="400" trigger="hover">
              <company-list :companyLists='companyLists' @refresh="refresh" />
              <div slot="reference" class="company-box">
                <div class="left">
                  <div class="title">切换</div>
                  <div class="name-2">{{ this.$store.getters.currentUserInfo.isPersonal ? '个人用户' : tenantName }}</div>
                </div>
                <div class="right">
                  <i class="el-icon-arrow-right" size="16px"></i>
                </div>
              </div>
            </el-popover>
            <div @click="handleLoginOut" class="quit">
              <img :src="require('@/assets/icon_quit.svg')" />
              退出登录
            </div>
          </div>
        </el-dropdown-menu>
        <!-- <el-dropdown-menu slot="dropdown" class="user_info_drop_menu">
          <el-dropdown-item @click.native="handleRedirectToUserInfo" id="personalcenter">个人中心</el-dropdown-item>
          <el-dropdown-item @click.native="handleLoginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu> -->
      </el-dropdown>
    </div>
    <pay-dialog ref="payDialog" v-model="payDialogVisible" :defaultCheckedId="defaultCheckedId"
      :defaultCheckedName="defaultCheckedName" :isExpiryed="currentUserInfo.expiryDays < 0"
      :productNameType="payNameType" closeable @success="handlePaymentSuccess" />
  </div>
</template>

<script>
import UserApi from '@/api/systemSetting';
import { getProductList } from '@/api/payment';
import AccountApi from '@/api/login';
import PayDialog from './payDialog.vue';
import { createTenant } from '@/api/company';
import switchCompany from '@/utils/switchCompany';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { fullScreen } from '@/utils/fullScreen';
import CompanyList from '@/components/common/companyList/index.vue';

export default {
  name: 'Header',
  components: {
    PayDialog,
    CompanyList,
  },
  data: function () {
    return {
      TRIALPRODUCTCODE: localStorage.userInfo ? JSON.parse(localStorage.userInfo).trialProductCode : '',
      // tenantName: '',
      userMenuVisible: false,
      isCollapse: false,
      payDialogVisible: false,
      expriryList: [],
      defaultCheckedId: '',
      defaultCheckedName: '',
      msgShowType: null,
      payNameType: '',
      visible: false,
      companyLists: [],
      sysFlag: 'homePage',

      active: this.$store.getters.firstUrl,
    };
  },
  watch: {
    // 点击1械导航，还是保持上一个选中状态
    $route (to) {
      let currentMenu = this.menuUrlMap[to.path];
      let url = '';
      if (currentMenu && currentMenu[0] && this.headerList[0]) {
        let info = this.headerList.find(t => t.sysFlag.toUpperCase() === currentMenu[0].sysFlag.toUpperCase());
        this.initSysFlag(info);
        url = info.url;
      }
      if (to.path !== '/1xie') {
        this.active = url;
      }
    },
  },
  methods: {
    ...mapActions({
      getMenuInfo: 'getMenuInfo',
    }),
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),
    handleFullscreen (type) {
      this.$emit('fullScreenChanged', type);
      fullScreen();
    },
    initSysFlag (info) {
      this.sysFlag = info.sysFlag;
    },
    refresh () {
      // this.$emit('refreshAllTabs');
      this.visible = false;
    },
    async switchCurrentCompany (item) {
      await switchCompany(item.tenantId);
      this.updateUserInfo(JSON.parse(this.$local.get('userInfo')));
      this.$tabs.items = [];
      this.$router.push({
        path: '/workbench',
      });
      window.location.reload();
      this.visible = false;
    },
    visibleChange (val) {
      this.userMenuVisible = val;
      this.tenantName = this.$local.get('userInfo') ? JSON.parse(this.$local.get('userInfo')).tenantName : '';
      this.companyLists = JSON.parse(this.$local.get('ownerList'));
    },
    async save (type) {
      if (!this.company) return;

      const { employeeMsg } = JSON.parse(this.$local.get('userInfo'));

      const list = await createTenant({
        id: employeeMsg.id,
        tenantNames: [this.company],
      });

      await switchCompany(list.body[0].tenantId);

      this.updateUserInfo(JSON.parse(this.$local.get('userInfo')));

      this.company = '';
      this.dialogVisible = false;
      this.$message.success('企业创建成功');

      this.$tabs.items = [];
      this.$router.push({
        path: '/workbench',
      });
      window.location.reload();

      if (type === 2) {
        setTimeout(() => {
          this.$router.push({
            path: '/workbench/identification',
          });
        }, 2000);
      }
    },
    handleLoginOut () {
      // 退出操作
      this.$confirm('确认是否要退出？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        // 确认操作
        AccountApi.logout().then(() => {
          this.$local.clear();
          this.$session.clear();
          this.$router.push({
            name: 'LOGIN',
          });
        });
      });
    },
    handleCollapse () {
      this.isCollapse = !this.isCollapse;
      this.$emit('collapse', this.isCollapse);
    },
    handleRedirectToUserInfo () {
      this.visible = false;
      this.$router.push({
        name: 'USER_INFO',
      });
    },
    openHelpDoc () {
      window.open('https://www.yuque.com/yixiubao', '_blank');
    },
    goToFeedback () {
      this.$router.push({
        name: 'FeedbackLists',
      });
    },
    // openGuideModelHandle () {
    //   this.$emit('openGuideModel');
    // },
    handlePaymentSuccess () {
      UserApi.getDetailEmploy({ id: this.currentUserInfo.id, findDepartmentFlag: true, findRoleFlag: true }).then(
        (userDetail) => {
          this.$local.set('userInfo', userDetail.body);
          this.updateUserInfo(userDetail.body);
        },
      );
    },
    handleOpenedManually (obj) {
      const { defaultCheckedId, defaultCheckedName, payNameType } = obj;
      this.payDialogVisible = true;
      this.payNameType = payNameType;
      this.defaultCheckedId = defaultCheckedId;
      this.defaultCheckedName = defaultCheckedName;
    },
    showDropdown (step) {
      const docEle = step.options.restOption?.parentId;
      const higNode = step.node;
      if (docEle === 'guide_help_btn') {
        this.$refs.personl_help_btn.hide();
        document.querySelector('#personalcenter').style['z-index'] = '100';
      }
      this.$refs[docEle].show();
      higNode.style['z-index'] = '20000000';
    },

    handleSelectMenu (key) {
      const environment = process.env.VUE_APP_OFFICAL_WEBSITE_YI;
      if (key === '/1xie') {
        window.open(environment, '_blank');
        return;
      }
      this.active = key;
      this.$router.push({
        path: key,
      });
    },
  },
  computed: {
    ...mapGetters(['currentUserInfo', 'menuTree', 'menuIdMap', 'menuUrlMap']),
    headerList () {
      return this.menuTree.filter(t => t.level === 1 && t.type === 1).sort((a, b) => a.sort - b.sort);
    },
    currentModule () {
      let currentMenu = this.menuUrlMap[this.$route.path];
      if (currentMenu && currentMenu[0] && this.headerList[0]) {
        let info = this.headerList.find(t => t.sysFlag.toUpperCase() === currentMenu[0].sysFlag.toUpperCase());
        this.initSysFlag(info);
        return info.url;
      }
      return '';
    },
    tenantName () {
      return this.$local.get('userInfo') ? JSON.parse(this.$local.get('userInfo')).tenantName : '';
    },
  },
  created () {
    this.$eventBus.$on('openPaymentDialogManually', this.handleOpenedManually);

    if (!this.currentUserInfo) {
      this.payDialogVisible = false;
      return;
    }

    getProductList({ day: 30 }).then((res) => {
      this.msgShowType = res.body.flag;
      this.expriryList = res.body?.pages || [];
    });
    // let expiryDays = parseInt(this.currentUserInfo.expiryDays);
    // // 正式用户提前30天提醒付费
    // if (this.currentUserInfo.isFormal && expiryDays <= 30) {
    //   this.payDialogVisible = true;
    //   return;
    // }
    // // 试用用户提前3天提醒付费
    // if (!this.currentUserInfo.isFormal && expiryDays <= 3) {
    //   this.payDialogVisible = true;
    //   return;
    // }
    // this.payDialogVisible = false;
  },
  beforeDestroy () {
    this.$eventBus.$off('openPaymentDialogManually', this.handleOpenedManually);
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 56px;
  // line-height: 56px;
  display: flex;
  background: white;
  color: #242832;
  align-items: center;
  padding: 0 30px 0 15px;
  font-size: 14px;
  border-bottom: 1px solid rgba(113, 121, 141, 0.08);

  .menu {
    flex: 1;
    display: flex;

    .el-menu {
      margin-left: 10px;
      flex: 1;
      border: none;

      .el-menu-item {
        height: 46px;
        line-height: 46px;
      }
    }
  }

  .logo {
    width: 280px;
    font-size: 16px;
    font-size: 0px;
    vertical-align: middle;
    color: #242832;
  }

  .user_Info {
    color: #242832;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    &>div {
      display: flex;
      align-items: center;

      &>div.userInfo_name {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 8px;

        &>span:nth-child(2) {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 120px;
        }

        .head {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }

        .userInfo_name-left {

          // margin-right: 10px;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          .el-avatar {
            background: #a1cdfb;
          }
        }

        .userInfo_name-right {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .name {
            font-size: 14px;
            font-weight: 600;
            color: #1F2733;
          }

          .name-ellipsis {
            font-size: 12px;
            color: #5F6A7A;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 140px;
          }
        }
      }
    }
  }

  .payment_notice {
    height: 33px;
    background: #fee8c0;
    border-radius: 17px;
    font-size: 14px;
    font-weight: bolder;
    color: #242832;
    line-height: 33px;
    padding: 0 16px;
    letter-spacing: 1px;
    cursor: pointer;

    &>span {
      color: #d20000;
    }
  }

  .menu_action {
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
  }

  .help_center {
    padding: 5px 12px;

    &:hover {
      color: #0f89ff;
    }

    &_icon {
      color: #0f89ff;
      padding-right: 8px;
      vertical-align: middle;
    }
  }
}

.user-switch-quit-box {
  width: 350px;
  position: absolute;
  top: 0;
  right: -15px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px 6px rgba(31, 39, 51, 0.12);
  border-radius: 4px;
  padding: 25px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .name {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0 15px;
  }

  .company-box {
    width: 330px;
    background: #F2F4F7;
    border-radius: 4px;
    padding: 5px 0;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 10px;

      .title {
        font-weight: bold;
      }

      .name-2 {
        line-height: 1.5;
        font-size: 12px;
        font-weight: 400;
        color: #929AA6;
      }
    }

    .right {
      padding: 10px;
    }
  }

  .quit {
    cursor: pointer;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #FF5858;
    padding: 10px 0;
    margin-top: 10px;
    border-top: 1px solid #f0f0f0;

    >img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  .head {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.user_info_drop_menu {
  min-width: 120px;
}

/deep/ .el-menu-item:hover,
/deep/ .el-menu-item.is-active,
/deep/ .el-menu-item.is-active:hover {
  background: #E2F3FE !important;
  color: #237FFA;
}

.el-menu--horizontal>.el-menu-item {
  color: #242832;
}

.el-menu-item.is-active {
  color: #237FFA;
}

.el-avatar {
  background: #a1cdfb;
}
</style>
