<template>
  <el-dialog title="企业资料"  size="mini" :visible.sync="isShow" @close="handleClose" :append-to-body="true" :destroy-on-close="true" :lock-scroll="true" width="720px">
    <el-form :model="form" :rules="rules" label-width="100px" ref="JZForm">
      <el-row>
        <el-col :span="12">
          <el-form-item label="企业名称" prop="name">
            <el-input v-model="form.name" :disabled="authenticationStatus !== '1'" placeholder="请输入企业名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="组织编号" prop="code">
            <el-input v-model="form.code" readonly placeholder="请输入组织编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="经营类型" prop="businessType">
            <el-select v-model="form.businessType" placeholder="请选择经营类型">
              <el-option value="1" label="厂商"></el-option>
              <el-option value="2" label="服务商"></el-option>
              <el-option value="3" label="经销商"></el-option>
              <el-option value="4" label="其它"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公司规模" prop="scale">
            <el-select v-model="form.scale" placeholder="请选择公司规模" class="w-100">
              <el-option value="10人以下"></el-option>
              <el-option value="10~50人"></el-option>
              <el-option value="50~100人"></el-option>
              <el-option value="100~500人"></el-option>
              <el-option value="500人以上"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="企业介绍" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              rows="5"
              placeholder="请输入企业介绍"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div  class="dialog-footer" style="text-align: right;">
      <el-button @click="handleClose" size="mini">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" size="mini">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { updateTenant } from '@/api/company';
export default {
  name: '',
  methods: {
    initPage () {
      this.isShow = true;
      this.$nextTick(()=>{
        this.$refs.JZForm.clearValidate();
      });
    },
    handleClose () {
      this.isShow = false;
    },
    handleSubmit () {
      this.$refs.JZForm.validate(valid => {
        if (valid) {
          updateTenant(this.form).then(()=>{
            this.$emit('updateCompanyInfo');
            this.isShow = false;
          });
        }
      });
    },
  },
  data () {
    return {
      authenticationStatus: null, // 认证状态
      rules: {
        name: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: ['blur', 'change'],
          },
        ],
      },
      isShow: false,
      form: {
        name: '',
        code: '',
        businessType: '',
        scale: '',
        businessRange: '',
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
